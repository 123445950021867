<template>
  <div class="checkout-wrapper" :class="{scroll_hidden: isCartVisible}">
    <UpsellingModal v-if="getUpsellingProducts !== null && getUpsellingProducts.length > 0 && isOpenUpselling" @closeModal="isOpenUpselling = false"/>
    <CheckoutCart v-show="isCartVisible" :hiddenCart="isCartVisible" @submit="toggleCart" @toggleCart="isCartVisible = false" />
    <div class="container">
      <div class="checkout-margin">
        <h2 class="checkout-title">FINALIZA TU COMPRA</h2>
        <div class="checkout-mobile-wrapper">
          <h2>FINALIZA TU COMPRA</h2>
          <div @click="toggleCart" class="products-wrapper">
            <div class="products-header">
              <div class="articles">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 54 54" style="enable-background:new 0 0 54 54;" xml:space="preserve" fill="black" stroke="white">
                  <path fill="white" d="M27,0C12.112,0,0,12.112,0,27s12.112,27,27,27s27-12.112,27-27S41.888,0,27,0z M27,52C13.215,52,2,40.785,2,27    S13.215,2,27,2s25,11.215,25,25S40.785,52,27,52z"/>
                  <path fill="white" d="M27.707,18.935c-0.391-0.391-1.023-0.391-1.414,0l-10.5,10.5c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0    L27,21.056l9.793,9.793c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414    L27.707,18.935z"/>
                </svg>
                <div>
                  <p>{{cartItemCount}} artículo<template v-if="cartItemCount == 0 || cartItemCount > 1">s</template></p>
                  <span>Revisar pedido</span>
                </div>
              </div>
              <p>{{$filters.formatEUR(cartTotalPrice.total, true)}}</p>
            </div>
          </div>
          <div class="delivery-wrapper">
            <div class="delivery">
              <div class="delivery-info">
                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2C14.131 2 16 3.73 16 5.702C16 7.752 14.286 10.643 12 14.263C9.714 10.643 8 7.752 8 5.702C8 3.73 9.869 2 12 2ZM12 0C8.852 0 6 2.553 6 5.702C6 8.85 8.602 12.609 12 18C15.398 12.609 18 8.85 18 5.702C18 2.553 15.149 0 12 0ZM12 8C10.895 8 10 7.105 10 6C10 4.895 10.895 4 12 4C13.105 4 14 4.895 14 6C14 7.105 13.105 8 12 8ZM20 14H16.865C16.48 14.641 16.067 15.309 15.633 16H18.764L19.264 17H15L14.656 17.544L14.367 18H14.925L15.783 20H8.295L9.153 18H9.632L9.343 17.544L9 17H6.958L5.947 16H8.367C7.932 15.309 7.519 14.641 7.135 14H4L0 22H24L20 14ZM7.206 20H3.236L5 16.472L6.516 18H8.065L7.206 20ZM16.014 18H19.764L20.764 20H16.872L16.014 18Z" fill="#EE2737"/>
                </svg>
                <template v-if="shippingMethod === 'delivery'">
                  <div>
                    <p>Se entregará en:</p>
                    <span>{{deliveryAddress ? deliveryAddress.fullAddress : ''}}</span>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p>Para recoger en:</p>
                    <span>{{restaurant ? restaurant.address : ''}}</span>
                  </div>
                </template>
              </div>
              <button @click="$router.push({ name: 'modal-checkout' })" class="btn black">Cambiar</button>
            </div>
            <div class="delivery">
              <div class="delivery-info">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.91 13.34L18.546 9.314L18.092 8.908L14.419 12.007C13.744 11.869 13.017 12.075 12.525 12.625C11.789 13.448 11.86 14.713 12.684 15.449C13.508 16.185 14.772 16.114 15.508 15.29C16 14.74 16.123 13.995 15.91 13.34ZM12 2.694V0H16V2.694C14.561 2.451 13.408 2.456 12 2.694ZM20.851 4.758L22.258 3.351L23.672 4.765L22.351 6.086C21.889 5.602 21.387 5.159 20.851 4.758ZM2 9H10V11H2V9ZM0 13H8V15H0V13ZM3 17H10V19H3V17ZM24 14C24 19.523 19.523 24 14 24C11.21 24 8.7 22.845 6.889 21H10.169C11.307 21.631 12.608 22 14 22C18.411 22 22 18.411 22 14C22 9.589 18.411 6 14 6C12.608 6 11.307 6.369 10.169 7H6.889C8.7 5.155 11.21 4 14 4C19.523 4 24 8.477 24 14Z" fill="#EE2737"/>
                </svg>
                <span v-if="!orderTime">
                  <p>Lo antes posible</p>
                  <span>{{formatDate(restaurant ? restaurant.estimatedTime : '')}}</span>
                </span>
                <span v-else>
                  <p>Pedido programado</p>
                  <span>{{formatDate(orderTime / 1000)}}</span>
                </span>
              </div>
              <button @click="$router.push({ name: 'modal-timechange' })" class="btn black">Cambiar</button>
            </div>
          </div>
        </div>
        <div class="checkout-desktop-wrapper">
          <div class="checkout">
            <span v-if="!user" class="register-wrapper" @click="openRegisterModal">
              ¿Ya tienes cuenta o quieres registrarte?
              <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.68963 13L-3.55155e-08 12.1875L5.44444 6.49783L-5.32733e-07 0.8125L0.689629 -3.01447e-08L7 6.49783L0.68963 13Z" fill="#EE2737"/>
              </svg>
            </span>
            <div class="personal-info-wrapper">
              <h4>Completa tus datos personales:</h4>
              <div class="personal-info">
                <label for="personal-input">
                  <span>Nombre</span>
                  <input required type="text" v-model.lazy="form.name" />
                  <div class="errors-input" v-if="errors.hasOwnProperty('name')">
                    <span v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</span>
                  </div>
                </label>
                <label for="personal-input">
                  <span>Apellidos</span>
                  <input required type="text" v-model.lazy="form.surnames" />
                <div class="errors-input" v-if="errors.hasOwnProperty('surnames')">
                  <span v-for="(error, key) in errors.surnames" :key="key">{{ $t(error) }}</span>
                </div>
                </label>
                <label for="personal-input">
                  <span>Correo electrónico</span>
                  <input required :disabled="user" type="text" v-model.lazy="form.email" />
                <div class="errors-input" v-if="errors.hasOwnProperty('email')">
                  <span v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</span>
                </div>
                </label>
                <label for="personal-input">
                  <span>Teléfono</span>
                  <input required type="text" v-model.lazy="form.phone" placeholder="+34"/>
                <div class="errors-input" v-if="errors.hasOwnProperty('phone')">
                  <span v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</span>
                </div>
                </label>
                <div class="errors-input" v-if="errors.length > 0 && !errors.hasOwnProperty('errors')">
                  <span v-for="(error, key) in errors" :key="key">Completa los campos correctamente</span>
                </div>
              </div>
            </div>
            <div class="delivery-desktop-wrapper">
              <h4 v-if="shippingMethod === 'delivery'">Detalles de tu entrega:</h4>
              <h4 v-else>Detalles de tu recogida:</h4>
              <div class="delivery-wrapper">
                <div class="delivery">
                  <div class="delivery-info">
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 2C14.131 2 16 3.73 16 5.702C16 7.752 14.286 10.643 12 14.263C9.714 10.643 8 7.752 8 5.702C8 3.73 9.869 2 12 2ZM12 0C8.852 0 6 2.553 6 5.702C6 8.85 8.602 12.609 12 18C15.398 12.609 18 8.85 18 5.702C18 2.553 15.149 0 12 0ZM12 8C10.895 8 10 7.105 10 6C10 4.895 10.895 4 12 4C13.105 4 14 4.895 14 6C14 7.105 13.105 8 12 8ZM20 14H16.865C16.48 14.641 16.067 15.309 15.633 16H18.764L19.264 17H15L14.656 17.544L14.367 18H14.925L15.783 20H8.295L9.153 18H9.632L9.343 17.544L9 17H6.958L5.947 16H8.367C7.932 15.309 7.519 14.641 7.135 14H4L0 22H24L20 14ZM7.206 20H3.236L5 16.472L6.516 18H8.065L7.206 20ZM16.014 18H19.764L20.764 20H16.872L16.014 18Z" fill="#EE2737"/>
                    </svg>
                    <template v-if="shippingMethod === 'delivery'">
                      <div>
                        <p>Se entregará en:</p>
                        <span>{{deliveryAddress ? deliveryAddress.fullAddress : ''}}</span>
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        <p>Para recoger en:</p>
                        <span>{{restaurant ? restaurant.address : ''}}</span>
                      </div>
                    </template>
                  </div>
                  <button @click="$router.push({ name: 'modal-checkout' })" class="btn black">Cambiar</button>
                </div>
                <div class="delivery">
                  <div class="delivery-info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.91 13.34L18.546 9.314L18.092 8.908L14.419 12.007C13.744 11.869 13.017 12.075 12.525 12.625C11.789 13.448 11.86 14.713 12.684 15.449C13.508 16.185 14.772 16.114 15.508 15.29C16 14.74 16.123 13.995 15.91 13.34ZM12 2.694V0H16V2.694C14.561 2.451 13.408 2.456 12 2.694ZM20.851 4.758L22.258 3.351L23.672 4.765L22.351 6.086C21.889 5.602 21.387 5.159 20.851 4.758ZM2 9H10V11H2V9ZM0 13H8V15H0V13ZM3 17H10V19H3V17ZM24 14C24 19.523 19.523 24 14 24C11.21 24 8.7 22.845 6.889 21H10.169C11.307 21.631 12.608 22 14 22C18.411 22 22 18.411 22 14C22 9.589 18.411 6 14 6C12.608 6 11.307 6.369 10.169 7H6.889C8.7 5.155 11.21 4 14 4C19.523 4 24 8.477 24 14Z" fill="#EE2737"/>
                    </svg>
                    <div v-if="!orderTime">
                      <p>Lo antes posible:</p>
                      <span>{{formatDate(restaurant ? restaurant.estimatedTime : '')}}</span>
                    </div>
                    <div v-else>
                      <p>Pedido programado:</p>
                      <span>{{formatDate(orderTime / 1000)}}</span>
                    </div>
                  </div>
                  <button @click="$router.push({ name: 'modal-timechange' })" class="btn black">Cambiar</button>
                </div>
              </div>
            </div>
            <div class="detail-info-wrapper">
              <h4>Los últimos detalles:</h4>
              <div class="detail-info">
                <label for="detail-input">
                  <span>¿Tienes algún código descuento o promoción?</span>
                  <span class="coupon">
                    <input type="text" v-model="discountCode" @focus="setCouponsErrors" @click="removeErrors" />
                    <button @click="isValidCoupon" :class="[{disable_item: isPendingToValid}]" class="btn black">
                      <span v-if="!isPendingToValid">Validar</span>
                      <span v-else>Validando...</span>
                    </button>
                  </span>
                  <div v-for="(errorCoupon, i) in errorsCoupons" :key="i" class="errors-input">
                    <template v-for="(coupon, couponIn) in Object.keys(errorCoupon)" :key="couponIn">
                      <strong>{{ coupon }}:</strong> <br>
                      <template v-for="(message, messageIn) in  errorCoupon[coupon]" :key="messageIn">
                        {{ message }} <br>
                      </template> 
                    </template>
                  </div>
                  <div v-for="(error, i) in genericErrors" :key="i" class="errors-input">
                    <span>{{ error }}</span> 
                  </div>
                  <div v-if="existingCouponError.length > 0" class="errors-input">
                    <span>{{ existingCouponError }}</span>
                  </div>
                  <div v-if="validCoupons.length > 0" class="success-input list-coupons-valid">
                    <span>Cupones válidos: </span>
                    <template class="item-coupon-valid" v-for="(coupon, i) in unifyCoupons" :key="i">
                      <div class="success-input__coupon">
                          <span class="item-coupon-valid">{{ coupon }}</span>
                          <button @click="deleteValidCoupon(coupon)" class="remove-coupon-btn btn">Eliminar</button>
                        </div>
                    </template>
                  </div>
                </label>
                <label for="detail-input">
                  <span>¿Alguna otra petición o comentario?</span>
                  <textarea v-model="note" placeholder="Si tienes alguna alergia, necesitas cubiertos o es difícil encontrar tu portal, cuéntanoslo aquí."></textarea>
                </label>
              </div>
            </div>
            <div v-if="!user" class="acceptance-wrapper">
              <label @click="removeErrors">
                <input type="checkbox" required v-model="salesTerms">
                <span>Acepto las <a href="https://www.goiko.com/condiciones-venta-web/" target="_blank">condiciones de venta</a></span>
              </label>
              <span class="availability-error" v-if="!salesTerms">(Casilla obligatoria)</span>
              <label @click="removeErrors">
                <input type="checkbox" required v-model="privacyPolicy">
                <span>Acepto la <a href="https://www.goiko.com/es/politica_de_privacidad/" target="_blank">política de protección de datos personales</a></span>
              </label>
              <span class="availability-error" v-if="!privacyPolicy">(Casilla obligatoria)</span>
              <label>
                <input type="checkbox" v-model="acceptNotification">
                <span>Acepto recibir comunicaciones electrónicas comerciales de la marca</span>
              </label>
              <p class="error" v-if="showErrorLegal">Debes aceptar las condiciones de venta y la política de protección de datos personales</p>
            </div>
            <div class="pay-info-wrapper" v-if="user && user.paymentMethods && user.paymentMethods.length > 0">
              <h4>¿Qué tarjeta quieres utilizar?</h4> 
              <div class="payment-method">
                <div class="payment-method-info">
                  <label class="payment-method-input" @click="removeErrors" v-for="(card, idx) in formCardsNumbers" :key="idx">
                    <input type="radio" :value="card.value" v-model="savedCardNumber">
                    <span v-if="card.label === ''">La tarjeta ha sido borrada</span>
                    <span v-else>Tarjeta terminada en {{card.label}}</span>
                  </label>
                  <label class="payment-method-input">
                    <input type="radio" value="0" v-model="savedCardNumber">
                    <span>Una tarjeta nueva</span>
                  </label>
                  <p class="error" v-if="showErrorCard">Debes seleccionar una tarjeta</p>
                </div>
              </div>
            </div>
            <div class="pay-info-wrapper" v-else>
              <h4>Introduce los datos de tu tarjeta de pago</h4> 
            </div>
            <form role="form" id="paycometPaymentForm" action="javascript: function paycometPost() {return false;}" method="POST" >
              <input type="hidden" data-paycomet="jetID" :value="restaurant.paycometJetId">
              <div class="add-payment-method">
                <div class="add-payment-method-info" :class="{disabledPaycomet: this.savedCardNumber != 0}">
                  <PaycometForm></PaycometForm>
                  <label v-if="user" for="save-card" class="save-card-wrapper">
                    <input type="checkbox" id="save-card" v-model="saveCard">
                    <span>¿Quieres guardar tu tarjeta para próximas compras?</span>
                  </label>
                  <div id="paymentErrorMsg"></div>
                  <div class="errors-paymentMethod" v-show="errors.hasOwnProperty('paymentMethod')">
                    <span v-for="(error, key) in errors.paymentMethod" :key="key">Error: revisa que los datos de tu tarjeta estén correctos</span>
                  </div>
                </div>
                <div class="secure-payment">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.873 9.81C23.96 9.559 24 9.301 24 9.046C24 8.276 23.62 7.532 22.945 7.065C20.792 5.573 21.077 5.948 20.266 3.522C19.926 2.509 18.947 1.825 17.842 1.825H17.835C15.182 1.834 15.642 1.976 13.501 0.471C13.055 0.157 12.527 0 12 0C11.473 0 10.945 0.157 10.498 0.471C8.342 1.986 8.812 1.833 6.164 1.824H6.157C5.053 1.824 4.073 2.509 3.735 3.521C2.923 5.953 3.201 5.577 1.057 7.065C0.38 7.534 0 8.277 0 9.048C0 9.302 0.042 9.559 0.127 9.81C0.958 12.238 0.956 11.772 0.127 14.19C0.042 14.441 0 14.697 0 14.952C0 15.722 0.38 16.466 1.057 16.935C3.203 18.425 2.925 18.048 3.736 20.478C4.074 21.491 5.053 22.175 6.158 22.175H6.165C8.818 22.166 8.358 22.023 10.499 23.528C10.945 23.842 11.473 24 12 24C12.527 24 13.055 23.842 13.502 23.529C15.643 22.025 15.181 22.167 17.836 22.176H17.843C18.947 22.176 19.927 21.491 20.267 20.479C21.078 18.052 20.792 18.427 22.946 16.936C23.62 16.467 24 15.723 24 14.953C24 14.699 23.96 14.441 23.873 14.19C23.042 11.762 23.046 12.227 23.873 9.81ZM10.5 16.75L6 12.386L7.858 10.529L10.5 13.034L16.142 7.251L18 9.108L10.5 16.75Z" fill="#CCCCCC"/>
                  </svg>
                  <div>
                    <p>Pago 100% seguro</p>
                    <span>Utilizamos Paycomet para procesar nuestros pagos y garantizar que es un proceso 100% seguro.</span>
                  </div>
                </div>
              </div>
              <input type="submit" value="Enviar" id="paycometSubmit" style="display: none">
            </form>
            <div class="pay-btn-wrapper">
              <div>
                <button v-if="!isSubmitting" :disabled="cartItemCount === 0 ||
                  !salesTerms || !privacyPolicy || isSubmitting ||
                  !restaurant.isOpen || restaurant.isBusyMode"
                  class="btn" :class="{ disabled_button: cartItemCount === 0 ||
                  !salesTerms || !privacyPolicy ||
                  !restaurant.isOpen || restaurant.isBusyMode }"
                  @click="checkoutSubmit">Pagar {{$filters.formatEUR(cartTotalPrice.total, true)}}
                </button>
                <span v-else class="btn disabled_button">Procesando...</span>
              </div>
            </div>
            <div class="secure-payment">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.873 9.81C23.96 9.559 24 9.301 24 9.046C24 8.276 23.62 7.532 22.945 7.065C20.792 5.573 21.077 5.948 20.266 3.522C19.926 2.509 18.947 1.825 17.842 1.825H17.835C15.182 1.834 15.642 1.976 13.501 0.471C13.055 0.157 12.527 0 12 0C11.473 0 10.945 0.157 10.498 0.471C8.342 1.986 8.812 1.833 6.164 1.824H6.157C5.053 1.824 4.073 2.509 3.735 3.521C2.923 5.953 3.201 5.577 1.057 7.065C0.38 7.534 0 8.277 0 9.048C0 9.302 0.042 9.559 0.127 9.81C0.958 12.238 0.956 11.772 0.127 14.19C0.042 14.441 0 14.697 0 14.952C0 15.722 0.38 16.466 1.057 16.935C3.203 18.425 2.925 18.048 3.736 20.478C4.074 21.491 5.053 22.175 6.158 22.175H6.165C8.818 22.166 8.358 22.023 10.499 23.528C10.945 23.842 11.473 24 12 24C12.527 24 13.055 23.842 13.502 23.529C15.643 22.025 15.181 22.167 17.836 22.176H17.843C18.947 22.176 19.927 21.491 20.267 20.479C21.078 18.052 20.792 18.427 22.946 16.936C23.62 16.467 24 15.723 24 14.953C24 14.699 23.96 14.441 23.873 14.19C23.042 11.762 23.046 12.227 23.873 9.81ZM10.5 16.75L6 12.386L7.858 10.529L10.5 13.034L16.142 7.251L18 9.108L10.5 16.75Z" fill="#CCCCCC"/>
              </svg>
              <div>
                <p>Pago 100% seguro</p>
                <span>Utilizamos Paycomet para procesar nuestros pagos y garantizar que es un proceso 100% seguro.</span>
              </div>
            </div>
          </div>
          <div class="order-summary">
            <Cart></Cart>
            <div class="footer" v-if="cartItemCount > 0">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                <path d="M6.53579 0.109817L7.21116 0.934203L1.66847 6.5282L7.01308 12.3075L6.30941 13.1078L0.113147 6.50111L6.53579 0.109817Z" fill="black"/>
              </svg>
              <router-link :to="{ name: 'catalog' }">Volver a la carta y modificar pedido</router-link>
            </div>
          </div>
        </div>
        <span v-if="hasProductsToChoose">
          <ProductsToChoose @hasProductsToChoose="hasProductsToChoose = false" />
        </span>
        <DiscountProducts 
          v-if="openCouponsModal" 
          :pendingCoupons="pendingCoupons"
          :isPendingPromotionValid="isPendingPromotionValid"
          @validateTrigger="validateTrigger"
          @validatePromotion="validatePromotion"
          @cancelCouponProcess="cancelCouponProcess"
        />
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import CheckoutCart from '../components/CheckoutCart'
import ProductsToChoose from '../components/ProductsToChoose'
import Cart from '../components/Cart'
import UpsellingModal from '../components/UpsellingModal'
import PaycometForm from "@/components/Checkout/PaycometForm";
import DiscountProducts from '../components/DiscountProducts.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { formatDate } from '../commonMethods/methods'

export default {
  name: 'Checkout',
  components: {
    PaycometForm,
    CheckoutCart,
    UpsellingModal,
    Cart,
    ProductsToChoose,
    DiscountProducts
},
  props: {
    hiddenCart: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        name: '',
        surnames: '',
        email: '',
        phone: null,
      },
      discount: '',
      note: '',
      discountCode: '',
      salesTerms: false,
      privacyPolicy: false,
      acceptNotification: false,
      cardholder: '',
      newCardNumber: '',
      expirationDateMonth: '',
      expirationDateYear: '',
      cvv: '',
      savedCardNumber: 0,
      cardsNumbers: [],
      saveCard: false,
      showErrorLegal: false,
      showErrorCard: false,
      isCartVisible: false,
      isSubmitting: false,
      isOpenUpselling: true,
      errors: [],
      successCoupons: [],
      pendingCoupons: [],
      isPendingToValid: false,
      isPendingPromotionValid: false,
      hasProductsToChoose: false,
      openCouponsModal: false,
      validateCoupon: {
        code: null,
        triggerChosenProducts: [],
        promotionChosenProducts: []
      }
    }
  }, 
  computed: {
    ...mapState('User', ['user']),
    ...mapState('Cart', ['productsNotAvailable', 'coupons', 'productsToChoose', 'successCouponMsg', 'orderTime', 'restaurant', 'shippingMethod', 'deliveryAddress', 'existingCouponError', 'validCoupons', 'errorsCoupons', 'genericErrors']),
    ...mapGetters('Cart', ['getDeliveryAddress', 'getOrderInfo', 'getUpsellingProducts', 'cartItemCount', 'cartTotalPrice', 'getUpsellingProducts']),
    formCardsNumbers() {
      if (this.user) {
        return this.user.paymentMethods.map(paymentMethod => ({
        value: paymentMethod.id,
        label: paymentMethod.cardNumber
      }))
      }
      return []
    },
    isValidData() {
      let personalData = this.form.name !== '' && this.form.surnames !== '' && this.form.email !== '' && this.form.phone !== 0
      let inputsAddPaymentMethod = this.savedCardNumber == 0 && this.cardholder !== '' && this.newCardNumber !== '' && this.expirationDate !== '' && this.cvv !== ''
      let legal = this.salesTerms && this.privacyPolicy

      return !!((this.savedCardNumber == 0 && personalData && inputsAddPaymentMethod && legal)
        || (this.savedCardNumber != 0 && personalData && legal));
    },
    unifyCoupons() {
      let uniqueCoupons = []
      this.validCoupons.map(coupon => coupon.code).forEach(code => {
        if (!uniqueCoupons.includes(code)) {
          uniqueCoupons.push(code)
        }
      })

      return uniqueCoupons
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(url) {
        if (url.path.includes('/finalizar-compra') && localStorage.getItem('accessToken')) {
        this.fetchProfile()
          .then(data => {
            if (data) {
              this.form.name = data.name
              this.form.surnames = data.lastName
              this.form.email = data.email
              this.form.phone = data.phone
            }
            this.salesTerms = true
            this.privacyPolicy = true
          })
        }
      }
    }
  },
   methods: {
    ...mapActions('Cart', ['processCart', 'checkProductsCart', 'add CouponToCart', 'removeCoupon', 'addValidCoupon', 'handleErrorsCoupons', 'handleGenericErrors']),
    ...mapActions('User', ['fetchProfile']),
    ...mapMutations('Cart', ['RESET_ORDER_INFO_STORAGE', 'PRODUCT_PLU', 'WAS_PRODUCT_CHOOSED', 'EXISTING_COUPON', 'SET_COUPONS']),
    formatDate,

    removeErrors() {
      this.showErrorCard = false
      this.showErrorLegal = false
    },
 
    toggleCart() {
      this.isCartVisible = !this.isCartVisible
      let cartItems = []
			this.getOrderInfo.forEach(order => cartItems.push({
				item_id: order.plu,
				item_name: order.name
			}))
			this.$gtag.event('view_cart', {
				currency: 'EUR',
				value: this.cartTotalPrice.total / 100,
				items: cartItems
			})
    },

    async deleteValidCoupon(code) {
      this.removeCoupon(code)
      this.validateCoupon = {
        code: null,
        triggerChosenProducts: [],
        promotionChosenProducts: []
      }
    },  

    async isValidCoupon() {
      this.setCouponsErrors()
      
      if (this.discountCode === '') {
        return
      }

      this.pendingCoupons = []
      this.EXISTING_COUPON('')
      this.isPendingToValid = true

      this.validateCoupon =  {
        ...this.validateCoupon,
        code: this.discountCode,
        isValidating: false
      }

      this.checkCoupons()
    },
    async validateTrigger(triggerChosenProducts) {
      this.isPendingPromotionValid = true
      const { code } = JSON.parse(localStorage.getItem('coupons')).at(-1)
      this.validateCoupon = {
        ...this.validateCoupon,
        code,
        triggerChosenProducts,
        isValidating: true
      }

      this.checkCoupons()
    },
    async validatePromotion(promotionChosenProducts) {
      this.isPendingPromotionValid = true
      this.validateCoupon = {
        ...this.validateCoupon,
        promotionChosenProducts,
        isValidating: true
      }

      this.checkCoupons(false)
    },
    async checkCoupons (closeModal) {
      const { errorCoupons , genericErrors, pendingCoupons, validCoupons } = await this.checkProductsCart(this.validateCoupon)
      
      if (!Array.isArray(errorCoupons)) {
        this.handleErrorsCoupons(errorCoupons)
        this.isPendingToValid = false
        this.openCouponsModal = false
        return
      } else if (genericErrors.length > 0) {
        this.handleGenericErrors({ genericErrors, coupon: this.discountCode })
        this.isPendingToValid = false
        return
      } else if (pendingCoupons.length > 0) {
        this.pendingCoupons = pendingCoupons
        this.openCouponsModal = true
        this.isPendingPromotionValid = false
        return
      } else {
        this.openCouponsModal = false
      }

      if (validCoupons.length > 0) {
        this.addValidCoupon(validCoupons)
      }

      if (this.existingCouponError.length > 0) {
        this.openCouponsModal = false
      }
      
      if (closeModal) {
        this.openCouponsModal = closeModal
      }
      
      this.isPendingToValid = false
      this.isPendingPromotionValid = false
    },
    cancelCouponProcess() {
      this.setCouponsErrors()
      this.openCouponsModal = false
    },  
    setCouponsErrors() {
      this.handleErrorsCoupons(null)
      this.handleGenericErrors(null)
      this.validateCoupon = {
        code: null,
        triggerChosenProducts: [],
        promotionChosenProducts: []
      }
    },
    checkoutSubmit() {
      this.isSubmitting = true
      this.errors = []
      if (this.savedCardNumber == 0) {
        document.getElementById('paycometSubmit').click()
      }

      const elementExist = setInterval(() => {
        let elm = null
        if (this.savedCardNumber == 0) {
          this.isSubmitting = false
          const inputToken = document.querySelector("input[name=paytpvToken]")
          if (inputToken) {
            elm = inputToken.value
          }
        } else {
          elm = this.savedCardNumber
        }

        if (elm!=null) {
          this.isSubmitting = true
          let checkoutData = {
            personalData: {
              name: this.form.name,
              surnames: this.form.surnames,
              email: this.form.email,
              phone: this.form.phone,
              note: this.note,
              privacyPolicy: this.privacyPolicy,
              salesTerms: this.salesTerms
            },
            paymentMethod: elm,
            acceptNotification: this.acceptNotification,
            isPaymentMethodSaved: this.saveCard,
            orderTime: this.orderTime,
          }

          this.processCart(checkoutData)
            .then((response) => window.location.href = response.paycometUrl)
            .catch(err => {
              if (err.hasOwnProperty('errors')) {
                this.errors = err.errors
                if (this.errors.hasOwnProperty('name') || this.errors.hasOwnProperty('surnames') || this.errors.hasOwnProperty('email') || this.errors.hasOwnProperty('phone')) {
                  window.scrollTo({
                    top: window.innerWidth <= 767 ? 300 : 0,
                    behavior: "smooth"
                  })
                }
              } else {
                this.errors = err
                window.scrollTo({
                  top: window.innerWidth <= 767 ? 300 : 0,
                  behavior: "smooth"
                })
              }
            })
            .finally(() => {
              this.isSubmitting = false
            })
            clearInterval(elementExist)
        }
      }, 500)
    },
    openRegisterModal() {
      this.$router.push({ name: 'register-checkout' })
    },
    openDataModal() {
      this.$router.push({ name: 'modal-checkout' })
    }
  },
  beforeMount() {
    if(!this.restaurant || !this.deliveryAddress || !this.getOrderInfo) {
      this.RESET_ORDER_INFO_STORAGE()
      this.$router.push({ name: 'home' })
    }
  },
 async mounted() {
    const setCouponsValid = (validCoupons) => {
      if (validCoupons.length > 0) {
        this.addValidCoupon(validCoupons)
      }
    }

    const { errorCoupons, validCoupons, pendingCoupons } =  await this.checkProductsCart()
    setCouponsValid(validCoupons)

    if (!Array.isArray(errorCoupons)) {
      this.handleErrorsCoupons(errorCoupons)
      this.isPendingToValid = false
      return
    } else if (pendingCoupons.length > 0) {
      let coupons = JSON.parse(localStorage.getItem('coupons'))
      const lastCouponChecked = coupons.at(-1)
      
      if (pendingCoupons[0].code === lastCouponChecked.code) {
        coupons.pop()
      }
      
      localStorage.setItem('coupons', JSON.stringify(coupons))
      this.SET_COUPONS(coupons)
      const { validCoupons } = await this.checkProductsCart()
      setCouponsValid(validCoupons)
      return
    } else if (validCoupons.length > 0) {
      this.addValidCoupon(validCoupons)
      return
    }

    let cartItems = []
    this.getOrderInfo.forEach(order => cartItems.push({
      item_id: order.plu,
      item_name: order.name
    }))
    this.$gtag.event('view_cart', {
      currency: 'EUR',
      value: this.cartTotalPrice.total / 100,
      items: cartItems
    })
  },
  unmounted() {
   this.EXISTING_COUPON('')
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-family: $font__headings;
  font-size: $fs-m-medium;
  color: $c-white;
  margin: 0px 0 30px 0;
  @include tablet {
    font-size: $fs-l-large;
  }
}

h3 {
  font-family: $font__headings;
  font-size: $fs-s-medium;
  margin: 0 0 10px 0;
}

h4 {
  font-size: $fs-xs-medium;
  font-weight: $fw-bold;
  margin: 0;
  margin-bottom: 10px;
}

.error {
  margin: 0;
  font-size: $fs-s-small;
  color: $c-red;
  padding-left: 5px;
}

a {
  text-decoration: none;
}

.container {
  @include container;
}

.checkout-margin {
  @include tablet {
    margin: 0 30px;
  }
}

.checkout-title {
  display: none;
  padding: 40px 0 20px;
  margin: 0;
   @include tablet {
    display: block;
   }

   @include desktop {
    padding-top: 56px;
   }
}

.checkout-wrapper {
  background-image: url('../assets/bg-texture.jpg');
  background-size: cover;
  background-position: center;

  h2 {
    color: $c-black;
  }
  
  .checkout {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $c-white;
    width: 100%;
  }

  &.scroll_hidden {
    overflow: hidden;
  }

  #paymentErrorMsg {
    font-size: $fs-m-small;
    color: $c-red;
    margin-bottom: 20px;
    text-align: center;
  }

  @include tablet {
    .checkout {
      max-width: 750px;
      background-color: unset;
    }

    .cart-modal {
      display: none;
    }

    &.scroll_hidden {
        overflow: auto;
    }
  }
}

.checkout-mobile-wrapper {
  background-color: $c-black;
  padding: 20px;
  color: $c-white;
  border-radius: 0 0 6px 6px;
  h2 {
    color: $c-white;
  }
  .products-wrapper {
    border: 1px solid $c-white;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 30px;

    .products-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .articles {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
        p {
          font-family: $font__main;
          font-weight: $fw-bold;
        }

        span {
          font-family: $font__main;
          font-size: $fs-s-small;
        }
      }
      p {
        font-family: $font__headings;
        font-size: $fs-m-medium;
      }
    }
  }
  p {
    margin: 0;
  }
  @include tablet {
    display: none;
  }
}

.checkout-desktop-wrapper {
  display: flex;
  gap: 20px;
}

.delivery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  .delivery-info {
    display: flex;
    align-items: center;
    margin-right: 30px;
    div {
      max-width: 170px;
    }
    svg {
      margin-right: 20px;
    }
  }
  p {
    font-size: $fs-s-small;
    font-weight: $fw-bold;
  }
  span {
    font-size: $fs-s-small;
  }
}

.register-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 20px 0;
  background-color: $c-white;
  color: $c-black;
  font-size: $fs-s-small;
  text-decoration: none;
  svg {
    margin-left: 10px;
  }
  @include tablet {
    position: absolute;
    right: 0;
  }
}

.delivery-desktop-wrapper {
  display: none;
  @include tablet {
    display: block;
    background-color: $c-black;
    color: $c-white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .delivery-wrapper {
      display: flex;
      margin-left: 20px;
      gap: 40px;
      .delivery {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        .delivery-info {
          margin-bottom: 20px;
          div {
            max-width: unset;
            p {
              margin: 0;
            }
          }
        }
        a {
          margin-left: 32px;
        }
      }
    }
  }
}

.personal-info-wrapper, .detail-info-wrapper, .add-payment-method, .payment-method {
  background-color: $c-white;
  padding: 20px 20px 0 20px;
  .personal-info, .detail-info, .payment-method-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    label {
      display: flex;
      flex-direction: column;
      span {
        font-family: $font__main;
        font-size: $fs-s-small;
      }
    }
  }

  .errors-input {
    color: $c-red;
    font-size: $fs-s-small;
    margin: 0px;
    margin-bottom: 10px;
  }

  .success-input {
    color: $c-green;
    font-size: $fs-s-small;
    margin: 0px;
    margin-bottom: 20px;


    &__coupon {
      display: flex;
      align-items: center;
      gap: 6px;
      .item-coupon-valid {
        min-width: 100px;
      }

      &--code {
        width: 500px;
      }

      .remove-coupon-btn {
        font-size: 10px;
        padding: 4px 10px;
      }
    }
  }

  .errors-paymentMethod {
    color: $c-red;
    font-size: $fs-m-small;
    margin: 0px;
    margin-bottom: 30px;
    text-align: center;
  }

  .cart-empty__error {
    text-align: center;

    a {
      color: $c-red;
      font-size: $fs-l-small;
      text-decoration: underline;
    }
  }

  .coupon {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
    }
  }
}

.personal-info-wrapper {
  @include tablet {
    padding: 20px;
    padding-top: 30px;
    margin-bottom: 30px;
    .personal-info {
      flex-direction: row;
      width: 80%;
      gap: 0 40px;
      label {
        width: calc(50% - 20px);
      }
    }
  }
}

.detail-info-wrapper {
  @include tablet {
    .detail-info {
      label {
        &:first-child {
          width: 80%;
          input {
            width: calc(50% - 40px);
          }
        }
      }
    }
  }
}

.acceptance-wrapper {
  padding: 0 20px 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $c-white;
  label {
    span {
      a {
        text-decoration: underline;
        cursor: pointer;
        color: $c-black;
      }
    }
  }
  .availability-error {
    float: right;
    display: block;
    font-size: $fs-s-small;
    margin: -5px;
    margin-left: 3px;
    text-align: start;
    color: rgba($c-notice, .7);
  }

  .errors-input {
    color: $c-red;
    font-size: $fs-s-small;
    margin: 0px;
    margin-bottom: 10px;
  }

  @include tablet {
    margin-bottom: 30px;
  }
}

.pay-info-wrapper {
  padding: 20px 20px 0 20px;
  background-color: $c-white;
  .checkbox__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    flex-direction: column;
    margin-left: 7px;
    label {
      margin-bottom: 10px;
    }
  }
  @include tablet {
    .checkbox__wrapper {
      flex-direction: row;
      justify-content: space-between;
      max-width: 80%;
    }
  }
}

.add-payment-method {
  .add-payment-method-info {
    .expiration-date {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px 0;
      label {
        width: 50%;
        max-width: 150px;
        &:first-child {
          margin-right: 10px;
        }
      }
      div {
        display: flex;
        align-items: center;
      }
    }
    .save-card-wrapper {
      display: block;
      padding: 0 0 20px 20px;
    }
  }
  .secure-payment {
    display: none;
  }
  @include tablet {
    display: flex;
    margin-bottom: 30px;
    .add-payment-method-info {
      width: 60%;
    }
    .secure-payment {
      display: flex;
      align-items: flex-end;
      max-width: 200px;
      margin: 0 50px 50px;
      svg {
        margin-bottom: 32px;
      }
    }
  }
}

.payment-method {
  margin: 0;
  padding: 0;
  .payment-method-info {
    padding-left: 22px;
    .payment-method-input {
      margin-bottom: 10px;
    }
  }
}

.pay-btn-wrapper {
  margin: 0 auto;
  width: 70%;

  .btn {
    display: flex;
    justify-content: center;
    font-family: $font__headings;
    max-width: 450px;
    margin: 0 auto;
    text-transform: uppercase;
    width: 100%;

    .loading-msg {
      margin-right: 20px;
    }
  }
  @include tablet {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    a {
      display: block;
    }
  }
}

.secure-payment {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  svg {
    width: 100px;
  }
  p {
    font-size: $fs-xs-small;
    font-weight: $fw-bold;
    margin: 0 0 2px;
  }
  span {
    font-size: $fs-xs-small;
    display: block;
    color: $c-notice;
  }
  @include tablet {
    display: none;
  }
}

.order-summary {
  display: none;

  @include tablet {
    display: block;
    position: sticky;
    top: 90px;
    align-self: flex-start;
    min-width: 400px;
    max-width: 400px;

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;

      a {
        font-size: $fs-s-small;
        color: $c-black;
        margin-left: 5px;
        text-decoration: underline;
      }

      &.hidden_link {
        display: none;
      }
    }
  }
}

.input-group {
  height: 44px;
  border: 1px solid $c-border-input;
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 10px;
  padding: 0 10px;
  outline: none;
  &::placeholder {
    color: $c-placeholder;
    font-family: $font__main;
  }
}

.disabledPaycomet {
  height: 0 !important;
  overflow:hidden;
  /*position: relative;
  pointer-events: none !important;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ebebeb;
  }*/
}

.disabled_button {
  opacity: .5;
  pointer-events: none;
}

.list-coupons-valid{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item-coupon-valid{
  width: fit-content;
}
</style>
